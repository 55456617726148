import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom'; // Import useParams
import Logo from '../components/Logo';
import InputBox from '../components/InputBox';
import Button from '../components/Button';
import InvitePageTitle from '../components/InvitePageTitle';
import SocialMediaLinks from '../components/SocialMediaLinks';
import Subtitle from '../components/Subtitle';
import Loading from '../components/Loading';
import { doc, getDoc, updateDoc, arrayUnion, serverTimestamp } from 'firebase/firestore';
import { db } from '../firebase';

import '../css/InvitePage.css';

const InvitePage = () => {
    const { partyId } = useParams(); // Extract document ID from URL
    const [address, setAddress] = useState('');
    const [name, setName] = useState('');
    const [pills, setPills] = useState([]);
    const [hostName, setHostName] = useState(''); // State for host name
    const [participants, setParticipants] = useState({}); // State to hold participants
    const [midpointFound, setMidpointFound] = useState(null); // Track if midpoint is found
    const addressSuggestions = [
        '123 Main St',
        '456 Elm St',
        '789 Maple Ave',
        '101 Oak St',
        '202 Pine St',
    ];

    // Fetch party data from Firestore
    useEffect(() => {
        const fetchPartyData = async () => {
            try {
                const docRef = doc(db, 'Parties', partyId); // Create a reference to the document
                const docSnap = await getDoc(docRef); // Get the document
                
                if (docSnap.exists()) {
                    const data = docSnap.data();
                    const participantsData = data.participants || {}; // Ensure this matches your Firestore structure
                    setParticipants(participantsData); // Set participants data
                    setMidpointFound(data.midpointFound); // Set the midpoint status
                    const host = Object.keys(participantsData).find(user => participantsData[user].isHost);
                    setHostName(host || ''); // Set host name if found
                } else {
                    console.log('No such document!');
                }
            } catch (error) {
                console.error('Error fetching party data:', error);
            }
        };

        fetchPartyData();
    }, [partyId]);

    const handleAddressChange = (e) => setAddress(e.target.value);

    const handleAddressKeyDown = (e) => {
        if (e.key === 'Backspace' && address.trim() === '') {
            setPills((prev) => prev.slice(0, -1)); // Remove the last pill
        }
    };

    const handlePillRemove = (index) => setPills((prev) => prev.filter((_, i) => i !== index));

    const handlePillAdd = (suggestion) => setPills((prev) => [...prev, suggestion]);

    const handleNameChange = (e) => {
        if (e.target.value.length <= 15) setName(e.target.value);
    };

    // Handle joining the party
    const handleJoinParty = async () => {
        
        if (!name || pills.length === 0) {
            alert("Please enter your name and select at least one address.");
            return;
        }

        // Check if the name already exists in the participants
        if (participants[name]) {
            alert("This name is already taken. Please choose a different name.");
            return;
        }

        try {
            const docRef = doc(db, 'Parties', partyId);
            const participantData = {
                addresses: arrayUnion(...pills), // Add addresses to the array
                isHost: false,
                joinedAt: serverTimestamp() // Add current timestamp
            };

            // Update the participants map in Firestore
            await updateDoc(docRef, {
                [`participants.${name}`]: participantData // Use the user's name as the key
            });

            localStorage.setItem('partyId', partyId);
            localStorage.setItem('userName', name);
            localStorage.setItem('isHost', false); // Store isHost value

            // Redirect to the party page
            window.location.href = `https://findmidpoint.com/party/${docRef.id}`;

            alert("Successfully joined the party!");
        } catch (error) {
            console.error('Error joining party:', error);
            alert("Error joining party. Please try again.");
        }
    };

    // Render logic based on midpointFound status
    if (midpointFound === null) {
        return <div><Loading/></div>; // Show a loading state while fetching data
    }

    if (midpointFound) {
        return (
            <div className="expired-container">
                <Logo />
                <div className="expired-text">
                    <h1>Sorry, this invite link is expired :/</h1>
                </div>  
                <div className="button-container-expired">
                    <Button
                    text="Find New Midpoint"
                    onClick={() => {
                        window.location.href = 'https://findmidpoint.com/';
                    }}
                    primary={true}
                    />
                </div>
                <SocialMediaLinks />
            </div>
        );
    }

    return (
        <div className="InvitePage">
            <Logo />
            <h1 className="invite-title"><span>{hostName}</span> wants to meetup!</h1> 
            <div className="subtitle-container">
                <Subtitle
                    strings={["The Perfect Spot For Everyone", "Where Every Meetup Begins", "Bringing You One Step Closer"]}
                />
            </div>

            <div className="form-container">
                <InputBox
                    label="What's your address?"
                    placeholder="Enter your address"
                    value={address}
                    onChange={handleAddressChange}
                    onKeyDown={handleAddressKeyDown}
                    emoji="📍"
                    isAddressInput={true}
                    pills={pills}
                    onPillRemove={handlePillRemove}
                    onPillAdd={handlePillAdd}
                    suggestions={addressSuggestions}
                />
                <InputBox
                    label="What's your name?"
                    placeholder="Enter your name"
                    value={name}
                    onChange={handleNameChange}
                />
                <div className="char-counter">{name.length}/15</div>
            </div>
            <div className="button-container-invite">
                <Button text="JOIN PARTY" onClick={handleJoinParty} primary={true} />
            </div>
            <SocialMediaLinks />
        </div>
    );
};

export default InvitePage;
