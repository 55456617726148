import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { useEffect, useState } from 'react';
import Home from './pages/Home';
import InvitePage from './pages/InvitePage';
import PartyPage from './pages/PartyPage';
import ResultsPage from './pages/ResultsPage';
import Loading from './components/Loading';
import './css/Loading.css';

function App() {
  const [isMapsLoaded, setIsMapsLoaded] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const apiKey = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

    window.initMap = () => {
      setIsMapsLoaded(true);
      setLoading(false); // Stop the loading screen
    };

    const loadGoogleMapsScript = () => {
      if (!document.querySelector(`script[src*="maps.googleapis.com"]`)) {
        const script = document.createElement('script');
        script.src = `https://maps.googleapis.com/maps/api/js?key=${apiKey}&libraries=places&callback=initMap`;
        script.async = true; 
        script.defer = true; 
        document.head.appendChild(script);
      }
    };

    loadGoogleMapsScript();

    // Fallback timer in case the script fails to load
    const timer = setTimeout(() => {
      if (!isMapsLoaded) {
        setLoading(false);
      }
    }, 5000);

    return () => clearTimeout(timer); // Clean up the timer on unmount
  }, [isMapsLoaded]);

  // Display a loading screen until the Maps API is ready
  if (loading) return <Loading />;

  return (
    <Router>
      <Routes>
        <Route path="/" element={<Navigate to="/home" />} />
        <Route path="/home" element={<Home />} />
        <Route path="/invite/:partyId" element={<InvitePage />} />
        <Route path="/party/:partyId" element={<PartyPage />} />
        <Route path="/results/:partyId" element={<ResultsPage />} />
      </Routes>
    </Router>
  );
}

export default App;
