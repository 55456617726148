import React from 'react';
import '../css/ResultCard.css';
import shareIcon from '../assets/share_2.png'; 
import googleMapsLogo from '../assets/google-maps-icon.png';

const ResultCard = ({placeId, title, description, rating, distance, category}) => {
  
  const renderStars = (rating) => {
    const fullStars = Math.floor(rating) + (rating % 1 >= 0.75 ? 1 : 0);
    const halfStar = rating % 1 >= 0.25 && rating % 1 < 0.75;
    const emptyStars = 5 - fullStars - (halfStar ? 1 : 0);

    return (
      <>
        {Array(fullStars).fill().map((_, i) => (
          <span key={`full-${i}`} className="star filled">★</span>
        ))}
        {halfStar && (
          <span className="star half">
            <span className="star-fill">★</span>
            <span className="star-empty">★</span>
          </span>
        )}
        {Array(emptyStars).fill().map((_, i) => (
          <span key={`empty-${i}`} className="star">★</span>
        ))}
      </>
    );
  };

  const formatDistance = (distance) => {
    const dist = Number(distance);
    if (isNaN(dist)) return 'N/A';
    if (dist >= 1000 && dist < 1000000) return `${(dist / 1000).toFixed(2)}k`;
    if (dist >= 1000000) return `${(dist / 1000000).toFixed(2)}M`;
    return dist.toFixed(2);
  };

  const getFontSizeClass = (distance) => {
    const dist = Number(distance);
    if (isNaN(dist)) return 'small-text';
    if (dist >= 100 && dist < 1000) return 'medium-text';
    return 'normal-text';
  };

  const handleShare = async () => {
    const url = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`;

    try {
      if (/Mobi|Android/i.test(navigator.userAgent) && navigator.share) {
        // Web Share API only on mobile devices
        await navigator.share({
          title: '',
          text: '📍recommended by Midpoint!',
          url: url,
        });
      } else {
        // Desktop: Copy the link to clipboard
        await navigator.clipboard.writeText(url);
        alert('The link has been copied to your clipboard.');
      }
    } catch (err) {
      console.error('Sharing failed:', err);
    }
  };

  const handleGoogleMapsClick = () => {
    const url = `https://www.google.com/maps/search/?api=1&query=Google&query_place_id=${placeId}`;
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <div className="result-card">
      <div className="card-header">
        <h2 className="card-title">{title}</h2>
        <img 
          src={shareIcon} 
          alt="Share link" 
          className="external-link" 
          onClick={handleShare} 
        />
      </div>
      <p className="card-description">{description}</p>
      <div className="rating">
        {renderStars(rating)}
        <span className="rating-number">({rating})</span>
      </div>
      <div className="card-footer">
        <span className={`distance ${getFontSizeClass(distance)}`}>
          {formatDistance(distance)} mi
        </span>
      </div>
      <img 
        src={googleMapsLogo} 
        alt="Google Maps" 
        className="google-maps-logo"
        onClick={handleGoogleMapsClick}
      />
    </div>
  );
};

export default ResultCard;
