import React, { useState, useEffect } from 'react';
import Logo from '../components/Logo';
import Subtitle from '../components/Subtitle';
import InputBox from '../components/InputBox';
import DropdownBox from '../components/DropdownBox';
import Button from '../components/Button';
import SocialMediaLinks from '../components/SocialMediaLinks';
import { db, collection, addDoc, Timestamp } from '../firebase'; 

import '../css/Home.css';

const Home = () => {
  const [address, setAddress] = useState('');
  const [activity, setActivity] = useState('Restaurants');
  const [name, setName] = useState('');
  const [pills, setPills] = useState([]);
  const activities = ['Bars', 'Restaurants', 'Parks', 'Arcades', 'Theaters'];
  const [isLoaded, setIsLoaded] = useState(false);

  const addressSuggestions = [
    '123 Main St',
    '456 Elm St',
    '789 Maple Ave',
    '101 Oak St',
    '202 Pine St',
  ];

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoaded(true);
    }, 100);
    return () => clearTimeout(timer);
  }, []);

  const handleAddressChange = (e) => {
    setAddress(e.target.value);
  };

  const handleAddressKeyDown = (e) => {
    if (e.key === 'Backspace' && address.trim() === '') {
      setPills((prev) => prev.slice(0, -1)); 
    }
  };

  const handlePillRemove = (index) => {
    setPills((prev) => prev.filter((_, i) => i !== index));
  };

const handlePillAdd = (suggestion) => {
    if (!pills.includes(suggestion)) {
      setPills((prev) => [...prev, suggestion]); 
    }
  };

  const handleNameChange = (e) => {
    if (e.target.value.length <= 15) {
      setName(e.target.value);
    }
  };

const handleInviteFriends = async () => {
    if (!name.trim() || !pills.length || !activity) {
        alert('Please fill out your name, address, and select an activity.');
        return;
    }

    try {
        const docRef = await addDoc(collection(db, 'Parties'), {
            createdAt: Timestamp.now(),
            midpointFound: false,
            category: activity,
            participants: {
                [name]: {
                    addresses: pills,
                    joinedAt: Timestamp.now(),
                    isHost: true, 
                }
            }
        });

        localStorage.setItem('partyId', docRef.id);
        localStorage.setItem('userName', name);
        localStorage.setItem('isHost', true); 

        const inviteLink = `https://findmidpoint.com/invite/${docRef.id}`;
        console.log(inviteLink);

        const isMobile = /Mobi|Android/i.test(navigator.userAgent);

        if (isMobile && navigator.share) {
            await navigator.share({
                title: 'Join my party!',
                text: `${name} wants to meet up. Find your Midpoint!`,
                url: inviteLink,
            });
            window.location.href = `https://findmidpoint.com/party/${docRef.id}`;
        } else {
            // For desktop users: copy the invite link to the clipboard
            await navigator.clipboard.writeText(inviteLink); // Copy the invite link to the clipboard
            alert(`The invite link has been copied to your clipboard: ${inviteLink}`);
            // Redirect to the party page after copying
            window.location.href = `https://findmidpoint.com/party/${docRef.id}`;
        }

    } catch (error) {
        console.error('Error adding document: ', error);
        // Handle the error without alerting the user
    }
};


  const handleFindMidpoint = async () => {
    if (!name.trim() || !pills.length || !activity) {
      alert('Please fill out your name, address, and select an activity.');
      return;
    }

    try {
      const docRef = await addDoc(collection(db, 'Parties'), {
        createdAt: Timestamp.now(),
        midpointFound: true, 
        category: activity,
        participants: {
          [name]: {
            addresses: pills,
            joinedAt: Timestamp.now(),
            isHost: true, 
          }
        }
      });

      localStorage.setItem('partyId', docRef.id);
      localStorage.setItem('userName', name);
      localStorage.setItem('isHost', true); 

      window.location.href = `https://findmidpoint.com/results/${docRef.id}`;
    } catch (error) {
      console.error('Error adding document: ', error);
      alert('An error occurred while finding the midpoint. Please try again. Details: ' + error.message);
    }
  };

  return (
    <div className={`Home ${isLoaded ? 'loaded' : ''}`}>
      <div className="fade-in-element logo-wrapper">
        <Logo />
      </div>
      
      <div className="fade-in-element subtitle-wrapper">
        <Subtitle 
            strings={["The Perfect Spot For Everyone", "Where Every Meetup Begins", "Bringing You One Step Closer"]}
        />
      </div>
      
      <div className="form-container">
        <div className="fade-in-element address-input">
          <InputBox
            label="What's your address?"
            placeholder="Enter your address"
            value={address}
            onChange={handleAddressChange}
            onKeyDown={handleAddressKeyDown}
            emoji="📍"
            isAddressInput={true}
            pills={pills}
            onPillRemove={handlePillRemove}
            onPillAdd={handlePillAdd}
            suggestions={addressSuggestions}
          />
        </div>
        
        <div className="fade-in-element activity-dropdown">
          <DropdownBox
            label="What do you want to do?"
            options={activities}
            value={activity}
            onChange={(e) => setActivity(e.target.value)}
          />
        </div>
        
        <div className="fade-in-element name-input">
          <InputBox
            label="What's your name?"
            placeholder="Enter your name"
            value={name}
            onChange={handleNameChange}
          />
          <div className="char-counter">{name.length}/15</div>
        </div>
      </div>
      
      <div className="button-container">
        <div className="fade-in-element invite-button">
          <Button 
            text="INVITE FRIENDS" 
            onClick={handleInviteFriends} 
            primary={true} 
          />
        </div>
        <div className="fade-in-element midpoint-button">
          <Button 
            text="FIND MIDPOINT" 
            onClick={handleFindMidpoint} 
            primary={false} 
          />
        </div>
      </div>

      <SocialMediaLinks />
    </div>
  );

};

export default Home;




