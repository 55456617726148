import React from 'react';
import '../css/Button.css';

const Button = ({ text, onClick, primary }) => {

  return (
    <button
      className={`button ${primary ? 'primary' : 'secondary'}`}
      onClick={onClick}
    >
      {text}
    </button>
  );
};

export default Button;


