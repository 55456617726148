// src/components/PartyMember.js
import React from 'react';
import '../css/PartyMember.css'; // Styling for the component

const PartyMember = ({ name, color, isHost, isCurrentUserHost, onRemove }) => {
  return (
    <div className="party-member-card">
      <div className={`side-color ${color}`}></div>
      <span className="member-name">{name}</span>
      {/* Only show the remove button if the current user is the host and not the member */}
      {isHost && !isCurrentUserHost && (
        <button className="remove-btn" onClick={onRemove}>
          ✕
        </button>
      )}
    </div>
  );
};

export default PartyMember;
