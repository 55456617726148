import React, { useState, useEffect } from 'react';
import { db, doc, updateDoc, onSnapshot, getDoc } from '../firebase'; 
import Logo from '../components/Logo';
import PartyMember from '../components/PartyMember';
import Button from '../components/Button';
import SocialMediaLinks from '../components/SocialMediaLinks';
import Subtitle from '../components/Subtitle';
import TypewriterText from '../components/TypewriterText';
import { useParams, useNavigate } from 'react-router-dom'; 

import '../css/PartyPage.css';

const PartyPage = () => {
  const { partyId } = useParams(); 
  const navigate = useNavigate(); 
  const [members, setMembers] = useState([]);
  const [isRedirecting, setIsRedirecting] = useState(false); 
  const hostName = localStorage.getItem('userName'); 
  const isHost = localStorage.getItem('isHost') === 'true';

  const handleShare = async () => {
    const inviteLink = `https://findmidpoint.com/invite/${partyId}`;
    const isMobile = /Mobi|Android/i.test(navigator.userAgent);

    try {
      if (isMobile && navigator.share) {
        await navigator.share({
          title: 'Join my party!',
          text: `${hostName} wants to meet up. Find your Midpoint!`,
          url: inviteLink,
        });
      } else {
        await navigator.clipboard.writeText(inviteLink);
        alert(`The invite link has been copied to your clipboard: ${inviteLink}`);
      }
    } catch (error) {
      console.error('Error sharing:', error);
    }
  };

  useEffect(() => {
    const unsubscribe = onSnapshot(doc(db, 'Parties', partyId), (doc) => {
      if (doc.exists()) {
        const data = doc.data();
        const participants = data.participants;

        const memberArray = Object.keys(participants).map((key) => ({
          name: key,
          color: participants[key].isHost ? 'green' : 'red',
        }));

        setMembers(memberArray);

        if (data.redirectToResults && !isRedirecting) {
          setIsRedirecting(true); 
          navigate(`/results/${partyId}`); 
        }
      } else {
        console.log('No such document!');
      }
    });

    return () => unsubscribe(); 
  }, [partyId, isRedirecting, navigate]);

  const removeMember = async (name) => {
    const updatedMembers = members.filter((member) => member.name !== name);
    setMembers(updatedMembers);

    const partyRef = doc(db, 'Parties', partyId);
    try {
      const partyDoc = await getDoc(partyRef);
      if (partyDoc.exists()) {
        const participants = partyDoc.data().participants;
        delete participants[name]; 
        await updateDoc(partyRef, { participants });
      }
    } catch (error) {
      console.error('Error removing member from Firestore: ', error);
    }
  };

  const findMidpoint = async () => {
    try {
      const partyRef = doc(db, 'Parties', partyId);
      await updateDoc(partyRef, { redirectToResults: true }); 
    } catch (error) {
      console.error('Error updating document: ', error);
    }
  };

  return (
    <div className="party-page">
      <Logo />
      {/* <TypewriterText 
            staticText="Welcome to the" 
            typeWords={["Party", "Group", "Entourage", "Family", "Gang"]} 
        /> */}
        <Subtitle
        strings={["Welcome To The Crew", "Let's Party People"]}
        />
      <div className="header-section">

        <button 
          onClick={handleShare}
          className="share-button"
        >
          forgot someone?
        </button>
      </div>

      
      <div className="members-container">
        {members.map((member, index) => (
          <PartyMember
            key={index}
            name={member.name}
            color={member.color}
            isHost={isHost} 
            isCurrentUserHost={member.name === hostName} 
            onRemove={() => removeMember(member.name)}
          />
        ))}
      </div>

      <div className="button-container-party">
        {isHost ? (
          <Button 
            text="FIND MIDPOINT" 
            onClick={findMidpoint} 
            primary={true} 
          />
        ) : (
          <Button 
            text="WAITING ON HOST" 
            primary={false} 
            className="disabled-button" 
          />
        )}
      </div>

      <SocialMediaLinks />
    </div>
  );
};

export default PartyPage;
