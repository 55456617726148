import React, { useState, useEffect, useRef } from 'react';
import '../css/SocialMediaLinks.css'; // Add styling in a separate CSS file

// Icons from react-icons library
import { FaInstagram, FaLinkedin, FaSpotify } from 'react-icons/fa';
import { FaXTwitter, FaTiktok, FaCompactDisc} from "react-icons/fa6";


const SocialMediaLinks = () => {
  const [isPlaying, setIsPlaying] = useState(true);
  const audioRef = useRef(null);

  useEffect(() => {
    audioRef.current = new Audio(`${process.env.PUBLIC_URL}/audio/receipts.mp3`);
    audioRef.current.loop = true; // Set loop to continuously play audio
  }, []);

  const togglePlay = () => {
    setIsPlaying((prev) => !prev);

    if (!isPlaying) {
      audioRef.current.pause();
    } else {
      audioRef.current.play();
    }
  };

  return (
    <div className="social-media-links">
      <a href="https://www.tiktok.com/@findmidpoint" target="_blank" rel="noopener noreferrer">
        <FaTiktok className="social-icon tiktok" />
      </a>
      <a href="https://www.instagram.com/findmidpoint" target="_blank" rel="noopener noreferrer">
        <FaInstagram className="social-icon" />
      </a>
      <a href="https://linkedin.com" target="_blank" rel="noopener noreferrer">
        <FaLinkedin className="social-icon" />
      </a>
      <a href="https://spotify.com" target="_blank" rel="noopener noreferrer">
        <FaSpotify className="social-icon" />
      </a>

      <div className="vinyl-container" onClick={togglePlay}>
        <FaCompactDisc className={`vinyl-icon ${isPlaying ? 'stopped' : 'spinning'}`} />
      </div>
    </div>
  );
};

export default SocialMediaLinks;