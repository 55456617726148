import React, { useState, useEffect } from 'react';
import '../css/Subtitle.css';

const Subtitle = (strings) => {
    
  const phrases = strings.strings

  const [phraseIndex, setPhraseIndex] = useState(0);
  const [currentPhrase, setCurrentPhrase] = useState('');
  const [isDeleting, setIsDeleting] = useState(false);

  useEffect(() => {
    const timeout = setTimeout(() => {
      if (!isDeleting && currentPhrase === phrases[phraseIndex]) {
        setTimeout(() => setIsDeleting(true), 2000);
        return;
      }

      if (isDeleting && currentPhrase === '') {
        setIsDeleting(false);
        setPhraseIndex((prevIndex) => (prevIndex + 1) % phrases.length);
        return;
      }

      const nextPhrase = isDeleting
        ? phrases[phraseIndex].substring(0, currentPhrase.length - 1)
        : phrases[phraseIndex].substring(0, currentPhrase.length + 1);

      setCurrentPhrase(nextPhrase);
    }, isDeleting ? 50 : 100);

    return () => clearTimeout(timeout);
  }, [currentPhrase, isDeleting, phraseIndex]);

  return <h2 className="subtitle">{currentPhrase}</h2>;
};

export default Subtitle;