import React, { useEffect, useState } from 'react';

const TypewriterText = ({ staticText, typeWords }) => {
    const [currentWord, setCurrentWord] = useState('');
    const [index, setIndex] = useState(0);
    const [isDeleting, setIsDeleting] = useState(false);

    useEffect(() => {
        const type = () => {
            const fullWord = typeWords[index];

            if (isDeleting) {
                setCurrentWord((prev) => fullWord.substring(0, prev.length - 1));
            } else {
                setCurrentWord((prev) => fullWord.substring(0, prev.length + 1));
            }

            if (!isDeleting && currentWord === fullWord) {
                setTimeout(() => {
                    setIsDeleting(true);
                }, 2000);
            } else if (isDeleting && currentWord === '') {
                setIsDeleting(false);
                setIndex((prev) => (prev + 1) % typeWords.length);
            }
        };

        const typingSpeed = isDeleting ? 100 : 200;
        const typingInterval = setTimeout(type, typingSpeed);

        return () => clearTimeout(typingInterval); // Cleanup the timeout on unmount
    }, [currentWord, isDeleting, index, typeWords]);

    return (
        <h1 className="results-title">
            {staticText} <span id='purple-text'>{currentWord}</span>
        </h1>
    );
};

export default TypewriterText;
