// Loading.js
import React from 'react';
import '../css/Loading.css'; // Make sure this path is correct

const Loading = () => {
  return (
    <div className="loader-container">
      <div className="spinner-container">
        <div className="spinner">
          <div className="spinner-circle"></div>
        </div>
        <div className="loading-text">loading <span>midpoint</span></div>
      </div>
    </div>
  );
};

export default Loading;
