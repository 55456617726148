// src/firebase.js
import { initializeApp } from 'firebase/app';
import { getFirestore, doc, getDoc, updateDoc, collection, onSnapshot, addDoc, Timestamp } from "firebase/firestore";
import { getAnalytics } from "firebase/analytics";

const firebaseConfig = {
  apiKey: "AIzaSyC4yL1hbsn-PIod3nScABY8madt5r-kBSw",
  authDomain: "midpoint-bfe80.firebaseapp.com",
  databaseURL: "https://midpoint-bfe80-default-rtdb.firebaseio.com",
  projectId: "midpoint-bfe80",
  storageBucket: "midpoint-bfe80.appspot.com",
  messagingSenderId: "432795145432",
  appId: "1:432795145432:web:3afa298ce86183a5d5b3ad",
  measurementId: "G-XGP8BRYD9B"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firestore
const db = getFirestore(app);
const analytics = getAnalytics(app);

export { db, doc, getDoc, updateDoc, collection, addDoc, onSnapshot, Timestamp };
