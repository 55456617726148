import React, { useState, useEffect } from 'react';
import { db, doc, getDoc, updateDoc } from '../firebase';
import Logo from '../components/Logo';
import Button from '../components/Button';
import SocialMediaLinks from '../components/SocialMediaLinks';
import ResultCard from '../components/ResultCard';
import TypewriterText from '../components/TypewriterText';
import '../css/ResultsPage.css';

const ResultsPage = () => {
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [showContent, setShowContent] = useState(false);

  const geocodeAddress = async (address) => {
    return new Promise((resolve, reject) => {
      const geocoder = new window.google.maps.Geocoder();
      geocoder.geocode({ address }, (results, status) => {
        if (status === 'OK' && results[0]) {
          const location = results[0].geometry.location;
          resolve({ lat: location.lat(), lng: location.lng() });
        } else {
          reject(new Error(`Geocoding failed: ${status}`));
        }
      });
    });
  };

  const calculateMidpoint = (coordinates) => {
    const totalCoords = coordinates.length;
    const sumLat = coordinates.reduce((sum, coord) => sum + coord.lat, 0);
    const sumLng = coordinates.reduce((sum, coord) => sum + coord.lng, 0);

    return {
      latitude: sumLat / totalCoords,
      longitude: sumLng / totalCoords,
    };
  };

  const calculateDistance = (point1, point2) => {
    const R = 3959; 
    const dLat = (point2.lat - point1.lat) * (Math.PI / 180);
    const dLng = (point2.lng - point1.lng) * (Math.PI / 180);
    const a =
      Math.sin(dLat / 2) * Math.sin(dLat / 2) +
      Math.cos(point1.lat * (Math.PI / 180)) *
        Math.cos(point2.lat * (Math.PI / 180)) *
        Math.sin(dLng / 2) * Math.sin(dLng / 2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    return R * c;
  };

  const isRelevantPlace = (place) => {
    const excludedTypes = ['locality', 'political', 'lodging', 'convenience_store'];
    const relevantTypes = ['restaurant', 'cafe', 'bar', 'park', 'museum'];

    return (
      place.types.some((type) => relevantTypes.includes(type)) &&
      !place.types.some((type) => excludedTypes.includes(type))
    );
  };

  const fetchNearbyPlaces = async (midpoint, category) => {
    const MAX_RADIUS = 50000; // Set a maximum search radius (in meters)
    const INITIAL_RADIUS = 5000; // Start with a 5km radius
    let currentRadius = INITIAL_RADIUS;
    let results = [];
  
    const service = new window.google.maps.places.PlacesService(document.createElement('div'));
  
    // Helper function to fetch places
    const fetchPlaces = (radius) => {
      return new Promise((resolve, reject) => {
        const request = {
          location: new window.google.maps.LatLng(midpoint.latitude, midpoint.longitude),
          radius: radius,
          keyword: category,
        };
  
        service.nearbySearch(request, (results, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            resolve(results);
          } else {
            reject(new Error(`Places API failed: ${status}`));
          }
        });
      });
    };
  
    while (currentRadius <= MAX_RADIUS && results.length < 12) {
      try {
        const places = await fetchPlaces(currentRadius);
        results = results.concat(places.filter(isRelevantPlace));
      } catch (error) {
        console.warn(error.message);
      }
  
      currentRadius *= 2; 
    }
  
    if (results.length < 12) {
      console.warn('Not enough relevant places found, performing a fallback search.');
  
      const fallbackRequest = {
        location: new window.google.maps.LatLng(midpoint.latitude, midpoint.longitude),
        radius: currentRadius, 
        keyword: 'place', 
      };
  
      try {
        const fallbackPlaces = await new Promise((resolve, reject) => {
          service.nearbySearch(fallbackRequest, (fallbackResults, fallbackStatus) => {
            if (fallbackStatus === window.google.maps.places.PlacesServiceStatus.OK) {
              resolve(fallbackResults);
            } else {
              reject(new Error(`Fallback Places API failed: ${fallbackStatus}`));
            }
          });
        });
  
        results = results.concat(fallbackPlaces);
      } catch (error) {
        console.error(error.message);
      }
    }
  
    return results.slice(0, 12);
  };

  useEffect(() => {
    const fetchData = async () => {
      try {
        const startTime = Date.now();
        const userName = localStorage.getItem('userName');
        const partyId = localStorage.getItem('partyId');

        if (!userName || !partyId) {
          throw new Error('Missing user information');
        }

        const partyDoc = await getDoc(doc(db, 'Parties', partyId));
        if (!partyDoc.exists()) {
          throw new Error('Party not found');
        }

        const partyData = partyDoc.data();
        const participants = partyData.participants || {};

        const addresses = Object.values(participants).flatMap(
          (user) => user.addresses || []
        );
        if (addresses.length === 0) {
          throw new Error('No addresses found');
        }

        const coordinates = await Promise.all(
          addresses.map((address) => geocodeAddress(address))
        );

        const midpoint = calculateMidpoint(coordinates);

        const places = await fetchNearbyPlaces(midpoint, partyData.category);

        const userAddress = participants[userName]?.addresses[0];
        const userCoords = await geocodeAddress(userAddress);
        
        const formattedResults = places.map((place) => ({ 
          key: place.place_id,
          placeId: place.place_id,
          title: place.name.length > 24 ? `${place.name.slice(0, 24)}...` : place.name,
          description: place.vicinity || 'No description available',
          rating: place.rating || 0,
          distance: calculateDistance(userCoords, {
            lat: place.geometry.location.lat(),
            lng: place.geometry.location.lng(),
          }).toFixed(1),
          category: place.types[0]
            ? `${place.types[0].charAt(0).toUpperCase()}${place.types[0].slice(1)}`
            : partyData.category.charAt(0).toUpperCase() + partyData.category.slice(1),
        }));        

        formattedResults.sort((a, b) => a.distance - b.distance);

        await updateDoc(doc(db, 'Parties', partyId), {
          locations: formattedResults,
          midpoint,
          midpointFound: true,
        });

        const endTime = Date.now();
        const timeElapsed = endTime - startTime;
        
        // Ensure loader shows for at least 5 seconds
        if (timeElapsed < 5000) {
          await new Promise(resolve => setTimeout(resolve, 5000 - timeElapsed));
        }

        setResults(formattedResults);
        setShowContent(true);
        setLoading(false);
      } catch (error) {
        console.error('Error:', error);
        setError(error.message);
        setLoading(false);
      }
    };

    fetchData();
  }, []);

  if (loading || !showContent) {
    return (
      <div className="loader-container">
        <div className="spinner-container">
          <div className="spinner">
            <div className="spinner-circle"></div>
          </div>
          <div className="loading-text">generating spots for <span>you</span></div>
        </div>
      </div>
    );
  }

  if (error) return <div>Error: {error}</div>;

  return (
    <div className="results-page">
      <Logo />
      <TypewriterText
        staticText="Awesome Spots for"
        typeWords={['You', 'Friends', 'Families', 'Foodies', 'Explorers']}
      />
      <div className="results-container">
        {results.map((result) => (
          <ResultCard
            key={result.key}
            placeId={result.placeId}
            title={result.title}
            description={result.description}
            rating={result.rating}
            distance={result.distance}
            category={result.category}
          />
        ))}
      </div>
      <div className="button-container-results">
        <Button
          text="NEW MIDPOINT"
          onClick={() => {
            window.location.href = 'https://findmidpoint.com/';
          }}
          primary={true}
        />
      </div>
      <SocialMediaLinks />
    </div>
  );
};

export default ResultsPage;

