import React, { useState, useRef, useEffect } from 'react';
import '../css/InputBox.css';

const InputBox = ({
  label,
  placeholder,
  value,
  onChange,
  onKeyDown,
  emoji,
  isAddressInput,
  pills,
  onPillRemove,
  onPillAdd,
}) => {
  const [suggestions, setSuggestions] = useState([]);
  const [showSuggestions, setShowSuggestions] = useState(false);
  const scrollRef = useRef(null);
  const dropdownRef = useRef(null);
  const inputRef = useRef(null);
  const autocompleteService = useRef(null);

  // Initialize Google Places Autocomplete service for address input
  useEffect(() => {
    if (isAddressInput && !autocompleteService.current && window.google) {
      autocompleteService.current = new window.google.maps.places.AutocompleteService();
    }
  }, [isAddressInput]);

  // Fetch suggestions only for address input
  const fetchSuggestions = (input) => {
    if (isAddressInput && autocompleteService.current && input) {
      autocompleteService.current.getPlacePredictions(
        { input, types: ['address'] },
        (predictions, status) => {
          if (status === window.google.maps.places.PlacesServiceStatus.OK) {
            const newSuggestions = predictions.map((p) => p.description);
            setSuggestions(newSuggestions);
          } else {
            setSuggestions([]);
          }
        }
      );
    } else {
      setSuggestions([]);
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setShowSuggestions(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => document.removeEventListener('mousedown', handleClickOutside);
  }, []);

  const handleSuggestionClick = (suggestion) => {
    onPillAdd(suggestion);
    onChange({ target: { value: '' } });
    setShowSuggestions(false);
  };

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    onChange(e);
    if (isAddressInput) {
      fetchSuggestions(inputValue);
      setShowSuggestions(true);
    }
  };

  const handlePlusClick = () => {
    inputRef.current?.focus();
  };

  const showPlaceholder = !isAddressInput || (isAddressInput && (!pills || pills.length === 0));
  const showPlusSign = isAddressInput && pills && pills.length > 0;

  return (
    <div className="input-box">
      <label>{label}</label>
      <div className="input-container" ref={dropdownRef}>
        <div className="pills-input-wrapper" ref={scrollRef}>
          {showPlusSign && (
            <button 
              className="add-address-button"
              onClick={handlePlusClick}
              aria-label="Add another address"
            >
              +
            </button>
          )}
          {isAddressInput &&
            pills?.map((pill, index) => (
              <div key={index} className="pill">
                <span>{pill}</span>
                <button onClick={() => onPillRemove(index)} className="pill-remove">
                  ×
                </button>
              </div>
            ))}
          <div className="input-wrapper">
            <input
              ref={inputRef}
              type="text"
              placeholder={showPlaceholder ? placeholder : ''}
              value={value}
              onChange={handleInputChange}
              onKeyDown={onKeyDown}
            />
            {emoji && <span className="input-emoji">{emoji}</span>}
          </div>
        </div>
        {isAddressInput && showSuggestions && suggestions.length > 0 && (
          <div className="suggestions-dropdown">
            {suggestions.map((suggestion, index) => (
              <div
                key={index}
                className="suggestion-item"
                onClick={() => handleSuggestionClick(suggestion)}
              >
                {suggestion}
              </div>
            ))}
          </div>
        )}
      </div>
    </div>
  );
};

export default InputBox;